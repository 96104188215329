import React from "react";
import { Helmet } from "react-helmet";
// import $ from "jquery";
import ClipboardJS from "clipboard";

import Layout from "../../components/layout";
import Footer from "../../components/footer";

import TrackingTags from "../../components/tracking_tags";

import "../../components/base/_thanks-generic.scss";

const config = require("../../../gatsby-config");
export default class GenericThanksPages extends React.Component {
  componentDidMount() {
    // Kopyalama foknsiyonu
    new ClipboardJS(".link-stuff__link-button");
  }

  render() {
    // Veri degiskenleri

    const title = "Teşekkürler | Türkiye | Havada Kalmasın";
    const description = "Hava kirliliğine karşı hemen harekete geç.";
    const social = {
      facebookText:
        "Hava kirliliğine karşı harekete geç, yaşadığın şehirde hava kirliliği ne durumda öğren! #HavadaKalmasın",
      twitterText:
        "Hava kirliliği nedeniyle dünyada her yıl 8 milyon insan erken ölüyor. Sen de hava kirliliğine karşı harekete geç, yaşadığın şehirde hava kirliliği ne durumda öğren! #HavadaKalmasın",
      twitterUser: "Greenpeace_Med",
      whatsappText:
        "Selam! Hava kirliliği nedeniyle dünyada her yıl 8 milyon insan erken ölüyor. Hava kirliliğine karşı sen de harekete geç, şehrinde hava kirliliği ne durumda öğren!",
    };
    const content = {
      cityName: "Türkiye",
      cityPageSlug: "turkiye",
      cityImage: {
        name: "Türkiye",
        src: "/social-assets/jenerik.jpg",
        alt: "Türkiye",
      },
      pollutedCityValueDso: 81,
      pollutedCityValueAb: 56,
      cityText:
        "Şimdi sen de kampanyayı arkadaşlarında paylaş, duymayan kalmasın.",
      districtNumber: 922,
      stationNumber: 313,
      pmStationNumber: 68,
    };

    return (
      <React.Fragment>
        <Helmet
          title={title}
          meta={[
            { name: "description", content: `${description}` },
            { name: "robots", content: "noindex,nofollow" },
          ]}
        >
          <html lang="tr" />
          <TrackingTags />
        </Helmet>

        <Layout>
          <header className="thanks-header">
            <div className="container">
              <div className="row middle-xs">
                <div className="logo-container col-md-3 col-sm-3 col-xs-6">
                  <a id="logo" className="thanks-header__logo" href="/">
                    <img
                      className="logo"
                      alt="Havada Kalmasın | Greenpeace"
                      src="/images/gp-logo.svg"
                    />
                  </a>
                </div>
                <div className="steps-container col-md-6 col-sm-6 col-xs-12">
                  <div className="steps">
                    <div className="steps__step steps__step-active">1</div>
                    <div className="steps__dots">
                      <div className="steps__dot"></div>
                      <div className="steps__dot"></div>
                      <div className="steps__dot"></div>
                      <div className="steps__dot"></div>
                      <div className="steps__dot"></div>
                    </div>
                    <div className="steps__step">2</div>
                  </div>
                </div>
                <div className="support-container col-md-3 col-sm-3 col-xs-6">
                  <a
                    className="thanks-header__support"
                    href="https://destek.greenpeace.org/havadakalmasin/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    DESTEK OL
                  </a>
                </div>
              </div>
            </div>
            <div className="thanks-header__message-container">
              <div className="container">
                <div className="row middle-xs center-xs">
                  <div className="col-md-8 col-sm-12 col-xs-12">
                    <h1 className="thanks-header__title">
                      Kampanyamıza katıldığın için teşekkürler!
                    </h1>
                    <p className="thanks-header__message">
                      Temiz hava hakkım havada kalmasın dedin, şimdi sen de
                      şehrinin karnesini arkadaşlarınla paylaş desteğini
                      tamamla.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <main className="thanks-main">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-12 col-xs-12">
                  <div className="city-values">
                    <img
                      className="city-values__image"
                      src={content.cityImage.src}
                      alt={content.cityImage.alt}
                    />
                    <p className="city-values__text">
                      Şimdi sen de kampanyayı arkadaşlarınla{" "}
                      <strong>paylaş, duymayan kalmasın.</strong>
                    </p>
                  </div>
                  <div className="link-stuff">
                    <div className="link-stuff__social link-stuff__facebook">
                      <a
                        href={
                          "https://facebook.com/sharer.php?u=" +
                          encodeURIComponent(
                            config.siteMetadata.siteUrl + "/"
                          ) +
                          "&quote=" +
                          encodeURIComponent(social.facebookText)
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook’da Paylaş
                      </a>
                    </div>
                    <div className="link-stuff__social link-stuff__twitter">
                      <a
                        href={
                          "https://twitter.com/share?url=" +
                          encodeURIComponent(config.siteMetadata.siteUrl) +
                          "&text=" +
                          encodeURIComponent(social.twitterText + " - ") +
                          "&via=" +
                          social.twitterUser
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter'da Paylaş
                      </a>
                    </div>
                    <div id="link-stuff__link2" className="link-stuff__link">
                      <button
                        id="copy2"
                        className="link-stuff__link-button"
                        data-clipboard-text={config.siteMetadata.siteUrl}
                      >
                        Linki kopyala
                      </button>
                      <div id="tooltip2">Kopyala</div>
                    </div>

                    <div className="mobile">
                      <div className="fb">
                        <a
                          href={
                            "https://facebook.com/sharer.php?u=" +
                            encodeURIComponent(config.siteMetadata.siteUrl) +
                            "&quote=" +
                            encodeURIComponent(social.facebookText)
                          }
                        >
                          Facebook’da Paylaş
                        </a>
                      </div>
                      <div className="tw">
                        <a
                          href={
                            "https://twitter.com/share?url=" +
                            encodeURIComponent(config.siteMetadata.siteUrl) +
                            "&text=" +
                            encodeURIComponent(social.twitterText + " - ") +
                            "&via=" +
                            social.twitterUser
                          }
                        >
                          Twitter'da Paylaş
                        </a>
                      </div>
                      <div className="wa">
                        <a
                          href={
                            "whatsapp://send?text=" +
                            social.whatsappText +
                            " https://act.gp/havada-kalmasin"
                          }
                        >
                          Whatsapp'ta Paylaş
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="city-abouts">
                    <div className="city-abouts-box">
                      <h3 className="city-abouts-box__title">
                        DSÖ<i>1</i> Sınır Değerini Aşan İl Sayısı
                      </h3>
                      <p className="city-abouts-box__number">
                        {content.pollutedCityValueDso}
                      </p>
                    </div>
                    <div className="city-abouts-box">
                      <h3 className="city-abouts-box__title">
                        AB<i>2</i> Sınır Değerini Aşan İl Sayısı
                      </h3>
                      <p className="city-abouts-box__number">
                        {content.pollutedCityValueAb}
                      </p>
                    </div>
                  </div>
                  <div className="notes">
                    <ol className="notes__list">
                      <li className="notes__item">Dünya Sağlık Örgütü</li>
                      <li className="notes__item">Avrupa Birliği</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </Layout>
      </React.Fragment>
    );
  }
}
